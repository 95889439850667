<template>
  <v-container
    style="max-width: 1200px"
    >
    <Header
      title="Talla y color"
      ></Header>
    <option-types
      ></option-types>
  </v-container>
</template>

<script>
import OptionTypes from '@/components/admin/option_types/OptionTypes'
import Header from '@/components/admin/shared/Header'

export default {
  components: {
    OptionTypes,
    Header
  }
}
</script>
