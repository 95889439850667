<template>
  <div
    class="d-flex flex-row align-center mb-3"
    >
    <div>
      <h1>
        {{ title }}

        <span
          class="text-subtitle-1"
          v-if="subtitle"
          >
          | {{ subtitle }}
        </span>
      </h1>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: false,
      type: String
    }
  }
}
</script>
