import gql from 'graphql-tag'

export const CreateOptionType = gql`
  mutation createOptionType($input: CreateOptionInput!) {
    createOptionType(input: $input) {
      success
    }
  }
`

export const DeleteOptionType = gql`
  mutation deleteOptionType($input: DeleteOptionInput!) {
    deleteOptionType(input: $input) {
      success
    }
  }
`

export const CreateOptionValue = gql`
  mutation createOptionValue($input: CreateOptionValueInput!) {
    createOptionValue(input: $input) {
      success
    }
  }
`

export const DeleteOptionValue = gql`
  mutation deleteOptionValue($input: DeleteOptionValueInput!) {
    deleteOptionValue(input: $input) {
      success
    }
  }
`
