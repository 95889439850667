<template>
  <v-card
    outlined
    class="rounded-lg d-flex flex-column"
    :style="{ width: $vuetify.breakpoint.mdAndUp ? '50%' : '100%'}"
    >
    <v-card-title
      class="text-subtitle-1"
      >
      Valores disponibles
    </v-card-title>

    <v-card-text
      class="text--primary"
      v-if="!loading"
      >
      <div>
        <template
          v-for="(value, i) in optionValues"
          >
          <div
            :key="value.id"
            class="body-1 px-3 py-2 rounded-lg d-flex flex-row justify-space-between"
            :class="!(i % 2) ? 'grey lighten-4' : ''"
            style=" gap: 8px"
            >
            <div>
              {{ value.value }}
            </div>

            <v-icon
              @click="closed(value.id)"
              style="cursor: pointer"
              >
              mdi-close
            </v-icon>
          </div>
        </template>
      </div>
    </v-card-text>

    <v-card-actions
      class="d-flex flex-row pa-4 mt-auto"
      >
      <v-text-field
        label="Nuevo valor"
        v-model="newValue"
        placeholder="36"
        dense
        hide-details 
        outlined
        solo
        flat
        class="rounded-l-lg rounded-r-0"
        ></v-text-field>

      <v-btn
        class="rounded-l-0 rounded-r-lg"
        :disabled="disableButton"
        style="height: 40px; margin-left: -1px"
        depressed
        color="primary"
        dense
        @click="addValue"
        >
        Agregar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { OptionValues } from '@/graphql/queries/option_types'
import { CreateOptionValue, DeleteOptionValue } from '@/graphql/mutations/products/option'

export default {
  data () {
    return {
      newValue: null,
      loading: false,
      optionValues: []
    }
  },

  props: {
    id: {
      required: true,
      type: String | Number
    }
  },

  watch: {
    id () {
      this.fetchOptionValues()
    }
  },

  created () {
    this.fetchOptionValues()
  },

  computed: {
    disableButton () {
      return !this.newValue || !!(this.optionValues || []).find( v => v.value == this.newValue)
    }
  },

  methods: {
    fetchOptionValues () {
      this.loading = true

      this.$apollo.query({
        query: OptionValues,
        variables: {
          optionTypeId: this.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.optionValues = res.data.optionValues
        this.loading = false
      })
    },

    closed (id) {
      this.$apollo.mutate({
        mutation: DeleteOptionValue,
        variables: {
          input: {
            optionValueId: id,
          }
        }
      }).then( res => {
        this.fetchOptionValues()
      })
    },

    addValue () {
      this.$apollo.mutate({
        mutation: CreateOptionValue,
        variables: {
          input: {
            optionTypeId: this.id,
            attributes: {
              value: this.newValue
            }
          }
        }
      }).then( res => {
        this.fetchOptionValues()
      })
    }
  }
}
</script>
