import gql from 'graphql-tag'
import { OptionType as OptionTypeFragment } from '@/graphql/fragments'
import { OptionValue as OptionValueFragment } from '@/graphql/fragments'

export const OptionTypes = gql`
  query optionTypes {
    optionTypes {
      ...${ OptionTypeFragment }
    }
  } 
`

export const OptionValues = gql`
  query optionValues($optionTypeId: ID!) {
    optionValues(optionTypeId: $optionTypeId) {
      ...${ OptionValueFragment }
    }
  } 
`
