<template>
  <v-card
    class="rounded-lg d-flex flex-column"
    >
    <v-card-text>
      <div
        class="d-flex flex-column flex-md-row align-stretch"
        style="gap: 8px"
        >
        <v-card
          outlined
          class="rounded-lg d-flex flex-column"
          :style="{ width: $vuetify.breakpoint.mdAndUp ? '50%' : '100%'}"
          >
          <v-card-title
            class="text-subtitle-1"
            >
            Opciones disponibles
          </v-card-title>

          <v-card-text
            class="text--primary"
            >
            <template
              v-for="optionType in optionTypes"
              >
              <div
                @click="selected = optionType"
                :class="selected == optionType ? 'primary white--text' : ''"
                class="body-1 px-3 py-2 rounded-lg"
                style="cursor: pointer"
                >
                {{ optionType.name }}
              </div>
            </template>
          </v-card-text>

          <v-card-actions
            class="d-flex flex-row pa-4 mt-auto"
            >
            <v-text-field
              label="Nueva opción"
              v-model="newOption"
              placeholder="Talle"
              class="rounded-l-lg rounded-r-0"
              hide-details 
              solo
              flat
              outlined
              dense
              ></v-text-field>
            <v-btn
              class="rounded-l-0 rounded-r-lg"
              :disabled="disableButton"
              style="height: 40px; margin-left: -1px"
              depressed
              color="primary"
              dense
              @click="addOption"
              >
              Agregar
            </v-btn>
          </v-card-actions>
        </v-card>

        <div
          class="align-self-center"
          >
          <v-icon
            color="primary"
            large
            >
            {{ $vuetify.breakpoint.mdAndUp ? 'mdi-chevron-right' : 'mdi-chevron-down' }}
          </v-icon>
        </div>

        <template
          v-if="selected"
          >
          <OptionValues
            :id="selected.id"
            />
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { CreateOptionType, DeleteOptionType } from '@/graphql/mutations/products/option'
import { OptionTypes } from '@/graphql/queries/option_types'
import OptionValues from './OptionValues'

export default {
  data () {
    return {
      optionTypes: [],
      newOption: null,
      selected: null
    }
  },

  components: {
    OptionValues
  },

  created () {
    this.fetchOptionTypes()
  },

  computed: {
    disableButton () {
      return !this.newOption || this.optionTypes.find( o => o.name == this.newOption)
    }
  },

  methods: {
    fetchOptionTypes () {
      this.$apollo.query({
        query: OptionTypes,
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.optionTypes = res.data.optionTypes

        this.selected = this.optionTypes[0]
      })
    },

    closed (id) {
      this.$apollo.mutate({
        mutation: DeleteProductOptionType,
        variables: {
          input: {
            id: id
          }
        }
      }).then( res => {
        this.fetchoptionTypes()
      })
    },

    addOption () {
      this.$apollo.mutate({
        mutation: CreateOptionType,
        variables: {
          input: {
            attributes: {
              name: this.newOption
            }
          }
        }
      }).then( res => {
        this.newOption = null
        this.fetchOptionTypes()
      })
    }
  }
}
</script>
